var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "任务ID", prop: "taskId" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入任务ID" },
                model: {
                  value: _vm.form.taskId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskId", $$v)
                  },
                  expression: "form.taskId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "描述", prop: "areaDesc" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入描述" },
                model: {
                  value: _vm.form.areaDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "areaDesc", $$v)
                  },
                  expression: "form.areaDesc",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "围栏类型：1.暂停、2.停止", prop: "type" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择围栏类型：1.暂停、2.停止" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.areaTypeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "围栏绘制", prop: "geomText" } },
            [
              _c("a-button", { on: { click: _vm.handleMapDraw } }, [
                _vm._v("在地图中绘制"),
              ]),
            ],
            1
          ),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "数据删除状态标志", prop: "delFlag" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入数据删除状态标志" },
                    model: {
                      value: _vm.form.delFlag,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "delFlag", $$v)
                      },
                      expression: "form.delFlag",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "租户ID", prop: "tenantId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入租户ID" },
                model: {
                  value: _vm.form.tenantId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tenantId", $$v)
                  },
                  expression: "form.tenantId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "状态(0正常，1停用)", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("road-scope-map", {
            ref: "roadScopeMapRef",
            attrs: { position: { top: "4%", left: "52%" } },
            on: { ok: _vm.handleOk },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }