<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="任务ID" prop="taskId">
        <a-input disabled v-model="form.taskId" placeholder="请输入任务ID" />
      </a-form-model-item>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="areaDesc">
        <a-input v-model="form.areaDesc" placeholder="请输入描述" />
      </a-form-model-item>
      <a-form-model-item label="围栏类型：1.暂停、2.停止" prop="type">
        <a-select placeholder="请选择围栏类型：1.暂停、2.停止" v-model="form.type">
          <a-select-option v-for="(d, index) in areaTypeOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="围栏绘制" prop="geomText">
        <a-button @click="handleMapDraw">在地图中绘制</a-button>
      </a-form-model-item>
      <!-- <a-form-model-item label="几何类型: 1.圆形 2.矩形 3.多边形 4.路线" prop="geomType">
        <a-select placeholder="请选择几何类型: 1.圆形 2.矩形 3.多边形 4.路线" v-model="form.geomType">
          <a-select-option value="">请选择字典生成</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="几何数据: 圆形[x,y,r] 矩形[x,y,x,y] 多边形[x,y,x,y,x,y] 路线[x,y,x,y,w]" prop="geomText">
        <a-input v-model="form.geomText" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item> -->
      <a-form-model-item label="数据删除状态标志" prop="delFlag" v-if="formType === 1">
        <a-input v-model="form.delFlag" placeholder="请输入数据删除状态标志" />
      </a-form-model-item>
      <a-form-model-item label="租户ID" prop="tenantId">
        <a-input v-model="form.tenantId" placeholder="请输入租户ID" />
      </a-form-model-item>
      <a-form-model-item label="状态(0正常，1停用)" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
      <road-scope-map ref="roadScopeMapRef" :position="{ top: '4%', left: '52%' }" @ok="handleOk" />
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleTaskArea, addVehicleTaskArea, updateVehicleTaskArea, getExecVehicleTaskArea, addExecVehicleTaskArea, updateExecVehicleTaskArea } from '@/api/iot/vehicleTaskArea'
import RoadScopeMap from '@/views/jt808/areaManager/modules/RoadScopeMap.vue'
export default {
  name: 'CreateForm',
  props: {
    params: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    RoadScopeMap
  },
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        taskId: null,
        name: null,
        areaDesc: null,
        type: null,
        geomType: null,
        geomText: null,
        delFlag: null,
        tenantId: null,
        updateBy: null,
        createBy: null,
        updateTime: null,
        createTime: null,
        status: 0
      },
      areaTypeOptions: [],
      statusOptions: [],
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        taskId: [
          { required: true, message: '任务ID不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        geomType: [
          { required: true, message: '几何类型: 1.圆形 2.矩形 3.多边形 4.路线不能为空', trigger: 'change' }
        ],
        geomText: [
          { required: true, message: '几何数据: 圆形[x,y,r] 矩形[x,y,x,y] 多边形[x,y,x,y,x,y] 路线[x,y,x,y,w]不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  async created() {
    await this.getDicts('iot_task_area_type').then((response) => {
      this.areaTypeOptions = response.data
    })
    await this.getDicts('sys_normal_disable').then((response) => {
      this.statusOptions = response.data
    })
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        taskId: null,
        name: null,
        areaDesc: null,
        type: null,
        geomType: null,
        geomText: null,
        delFlag: null,
        tenantId: null,
        updateBy: null,
        createBy: null,
        updateTime: null,
        createTime: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(taskId) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.taskId = taskId
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      // 当taskType='exec' 时调用接口为getExecVehicleTaskArea等，否则调用getVehicleTaskArea
      if (this.params.taskType === 'exec') {
        getExecVehicleTaskArea(id).then(response => {
          this.form = response.data
          // 将数字专为字符串
          this.form.taskId = this.form.schedulerTaskId
          this.form.type = this.form.type.toString()
          this.form.status = this.form.status.toString()
          this.open = true
          this.formTitle = '修改'
        })
      } else {
        getVehicleTaskArea(id).then(response => {
          this.form = response.data
          // 将数字专为字符串
          this.form.type = this.form.type.toString()
          this.form.status = this.form.status.toString()
          this.open = true
          this.formTitle = '修改'
        })
      }
    },
    handleOk(res) {
      console.log('handleOk', res)
      this.form.geomText = res.geomText
      this.form.geomType = res.geomType
      this.$refs.form.clearValidate('geomText')
    },
    handleMapDraw() {
      this.$refs.roadScopeMapRef.drawMap(this.form, false, this.readOnly)
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            // 当taskType='exec' 时调用接口为updateExecVehicleTaskArea等，否则调用updateVehicleTaskArea
            const apiToUpdate = this.params.taskType === 'exec' ? updateExecVehicleTaskArea : updateVehicleTaskArea
            apiToUpdate(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            // 当taskType='exec' 时调用接口为addExecVehicleTaskArea等，否则调用addVehicleTaskArea
            const apiToCall = this.params.taskType === 'exec' ? addExecVehicleTaskArea : addVehicleTaskArea
            apiToCall(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
