import request from '@/utils/request'

// 查询任务电子围栏列表
export function listVehicleTaskArea (query) {
  return request({
    url: '/iot/vehicleTaskArea/list',
    method: 'get',
    params: query
  })
}

// 查询任务电子围栏详细
export function getVehicleTaskArea (id) {
  return request({
    url: '/iot/vehicleTaskArea/' + id,
    method: 'get'
  })
}

// 新增任务电子围栏
export function addVehicleTaskArea (data) {
  return request({
    url: '/iot/vehicleTaskArea',
    method: 'post',
    data: data
  })
}

// 修改任务电子围栏
export function updateVehicleTaskArea (data) {
  return request({
    url: '/iot/vehicleTaskArea',
    method: 'put',
    data: data
  })
}

// 删除任务电子围栏
export function delVehicleTaskArea (id) {
  return request({
    url: '/iot/vehicleTaskArea/' + id,
    method: 'delete'
  })
}

// 导出任务电子围栏
export function exportVehicleTaskArea (query) {
  return request({
    url: '/iot/vehicleTaskArea/export',
    method: 'get',
    params: query
  })
}

// 查询执行任务电子围栏列表
export function listExecVehicleTaskArea (query) {
  return request({
    url: '/iot/vehicleTaskSchedulerArea/list',
    method: 'get',
    params: {
      ...query,
      schedulerTaskId: query.taskId
    }
  })
}

// 查询执行任务电子围栏详细
export function getExecVehicleTaskArea (id) {
  return request({
    url: '/iot/vehicleTaskSchedulerArea/' + id,
    method: 'get'
  })
}

// 新增执行任务电子围栏
export function addExecVehicleTaskArea (data) {
  return request({
    url: '/iot/vehicleTaskSchedulerArea',
    method: 'post',
    data: {
      ...data,
      schedulerTaskId: data.taskId
    }
  })
}

// 修改执行任务电子围栏
export function updateExecVehicleTaskArea (data) {
  return request({
    url: '/iot/vehicleTaskSchedulerArea',
    method: 'put',
    data: {
      ...data,
      schedulerTaskId: data.taskId
    }
  })
}

// 删除执行任务电子围栏
export function delExecVehicleTaskArea (id) {
  return request({
    url: '/iot/vehicleTaskSchedulerArea/' + id,
    method: 'delete'
  })
}

// 导出执行任务电子围栏
export function exportExecVehicleTaskArea (query) {
  return request({
    url: '/iot/vehicleTaskSchedulerArea/export',
    method: 'get',
    params: {
      ...query,
      schedulerTaskId: query.taskId
    }
  })
}
